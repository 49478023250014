.sticky {
  position: fixed;
  /*top: 95px;*/
  max-width: 100%;
  border-bottom: 2px solid grey;
  border-left: "2px solid lightgrey"
} 

.border-right {
  border-right: 2px solid lightgrey;
}
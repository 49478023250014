.link {
    display: flex;
    justify-content: center;
    text-decoration: none;
    height: 40px;
    width: 200px;
    align-items: center;
    background-color: #FFE60A;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    border: 2px solid transparent;
}

@media (hover: hover) and (pointer: fine) {
    .link:hover { 
      color: #01447e;
      border: 2px solid black;
      /*background-color: #f5dd0a;*/
    }
}

@media (max-width: 625px){
    .link {
        margin-bottom: 4em;
    }
}

.link p {
    color: black;
}

/*.link p {
    color: black;
}

.link:hover, .link:focus {
    background-color: black;
    color: yellow;
}*/
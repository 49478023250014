::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    /*background: #fff6a0; */
    border-radius: 10px;
    -box-shadow: inset 0 0 6px     rgba(0,0,0,0.5); 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888; 
border-radius: 10px;
-box-shadow: inset 0 0 6px     rgba(0,0,0,0.5); 
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: rgb(99, 99, 99); 
}


  .info-text {
    width: 60vw;
    text-align: left;
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: .5em;
  }

  .ulist {
    margin-top: 0;
  }

  .sub-text {
    font-size: 1.1em;
  }

  .bold {
    font-weight: bold;
  }

  .or {
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: underline;
    font-weight: bold;
  }

  .outer-text-wrapper {
    display: flex;
    justify-content: center;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60vw;
    align-self: center;
    word-break: normal;
    word-wrap: break-word;

    @media only screen and (max-width: 600px) {
      width: 80%;
      font-size: .9em;
    }
  }

  .container {
    padding: 40px 5%;
  }
  
  .heading-text {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  
  .heading-text span {
    font-weight: 100;
  }
  
  /* Responsive image gallery rules begin*/
  
  .image-gallery {
    /* Mobile first */
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .image-gallery .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .image-item img {
    width: 100%;
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
  }
  
  @media only screen and (min-width: 768px) {
    .image-gallery {
      flex-direction: row;
    }
  }
  
  /* overlay styles */
  
  .image-item {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(57, 57, 57, 0.502);
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.2s 0.1s ease-in-out;
    color: #fff;
    /* center overlay content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* hover */
  .image-item:hover .overlay {
    transform: scale(1);
  }
  
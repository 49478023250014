.slidecontainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0 20px 0 20px;
}

.title-and-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
  
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}
  
.slider:hover {
  opacity: 1;
}
  
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}
  
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}


.trigger {
  display: none;
}
.checker {
  background-position: left center;
  background-size: auto 100%;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  margin: 3px;
}
